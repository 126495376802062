import axios from "axios";

const service=axios.create({
	baseURL:"/",
	timeout: 60000,
	headers:{
		'Content-Type':'application/json',
		// 'Authorization': 'Bearer 4JnQFkyx9pv9jh2fy8qaARexbJkwchOz'
	},
	withCredentials:true,
})
// service.defaults.headers.common['Authorization'] = 'Bearer 4JnQFkyx9pv9jh2fy8qaARexbJkwchOz';



export default service

