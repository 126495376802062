import request from '@/untils/request'

// https://76zq660754.yicp.fun/api/kfh/queryFormDataUrl?phone=18041989031
// 左上饼图接口
export function searchPhone(params){
	return request({
		url:"/api/kfh/queryFormDataUrl",
		method:'get',
		// headers: {'Content-Type': 'multipart/form-data'},
		params
	})
}



