<template>
  <div class="entry" v-loading="fullscreenLoading">
    <el-input
      placeholder="请输入手机号"
      v-model="cellPhoneNumber"
      class="input-with-select search"
    >
      <el-button slot="append" icon="el-icon-search" @click="searchData"
        >查询</el-button
      >
    </el-input>
    <div v-if="isShow" class="linkAddress">
      <div class="link" v-for="(item, index) in linkAddress" :key="index">
        <div class="img"></div>
        <div class="text">{{ item.title }}</div>
        <div class="opera">
          <a class="text pointer" @click.prevent="lookUp(item)">查看</a>
          <a class="text pointer" @click.prevent="newlyAdded(item)">新增</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { searchPhone } from "@/api/entry.js";
export default {
  name: "entry",
  data() {
    return {
      cellPhoneNumber: "",
      linkAddress: [],
      messageExample: "",
      fullscreenLoading: false,
      timer: "",
      flag:true
    };
  },
  created() {
    let phoneNum = sessionStorage.getItem("searPhone");
    console.log("sjdifs", phoneNum);
    if (phoneNum) {
      this.cellPhoneNumber = phoneNum;
      this.searchData();
    }
  },
  computed:{
    isShow(){
      if(this.linkAddress.length>0){
        return true
      }else{
        return false
      }

    }
  },
  methods: {
    searchData() {
      this.fullscreenLoading=true
      let obj = {
        phone: this.cellPhoneNumber,
      };
      searchPhone(obj).then((res) => {
        this.fullscreenLoading=false
        // console.log(res);
        if (res.status == 200) {
          this.linkAddress = res.data.data;
          sessionStorage.setItem("searPhone", obj.phone);
          console.log(res.data.data);
           this.$message({
            type: "success",
            dangerouslyUseHTMLString: true,
            duration: 2000,
            showClose: true,
            message:
              '<strong style="font-size:20px">查询成功</strong>',
          });
        }else{
          this.$message({
            type: "error",
            dangerouslyUseHTMLString: true,
            duration: 2000,
            showClose: true,
            message:
              '<strong style="font-size:20px">查询失败</strong>',
          });

        }
      }).catch(()=>{
        this.fullscreenLoading=false
        this.$message({
          type: "error",
          dangerouslyUseHTMLString: true,
          duration: 2000,
          showClose: true,
          message:
            '<strong style="font-size:20px">查询失败</strong>',
        });
      })
    },
    lookUp(item) {
      // 防抖
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        if (item.queryUrl) {
          location.href = item.queryUrl;
        } else {
          // this.$message.error('没有内容可以查看,请点击新增');
          // console.log("消息提示实例对象", this.messageExample);
          if (this.messageExample) {
            this.messageExample.close();
          }
          this.messageExample = this.$message({
            type: "error",
            dangerouslyUseHTMLString: true,
            duration: 2000,
            showClose: true,
            message:
              '<strong style="font-size:20px">没有内容可以查看,请点击新增</strong>',
          });
          if(this.durationTimer){
            clearTimeout(this.durationTimer)
          }
          this.durationTimer=setTimeout(()=>{
            this.messageExample.close();
            clearTimeout(this.durationTimer)
            // console.log(11111);
          },2000)
        }
      }, 200);
    },
    newlyAdded(item) {
      if (item.addUrl) {
        location.href = item.addUrl;
      } else {
        // this.$message.error('没有内容可以查看,请点击新增');
        // this.$message({
        //   type: "error",
        //   dangerouslyUseHTMLString: true,
        //   duration: 2000,
        //   showClose: true,
        //   message:
        //     '<strong style="font-size:20px">没有内容可以查看,请点击新增</strong>',
        // });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.entry {
  padding: 40px 40px;
}
.search {
  height: 60px;
  ::v-deep .el-input__inner {
    height: 60px;
    border-color: #ccc;
    font-size: 30px;
  }
  ::v-deep .el-input-group__append,
  .el-input-group__prepend {
    border-color: #ccc;
    font-size: 30px;
  }
}
.linkAddress {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 20px;
  .link {
    width: 220px;
    height: 220px;
    background-color: #6272a6;
    border-radius: 3px;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // flex-direction: column;
    padding-top: 35px;
    margin-bottom: 40px;
    // padding: 0px 10px;
    .img {
      width: 92px;
      height: 92px;
      background: url("../assets/lianjie.png") no-repeat center center;
      background-size: 92px 92px;
      // margin-bottom: 20px;
      margin: 0 auto 10px;
    }
    // .icon{
    // 	font-size:92px;
    // 	color: #fff;
    // 	margin-bottom: 20px;
    // }
    .text {
      color: #fff;
      height: 28px;
      line-height: 28px;
      font-size: 22px;
      margin-bottom: 15px;
      text-align: center;
      padding: 0px 10px;
    }
    .opera {
      display: flex;
      justify-content: space-around;
    }
    .pointer {
      cursor: pointer;
    }
  }
}
</style>